import httpClient from './httpClient';
const prefix = 'staff'

export default {
  async login(username, password) {
    const { data: token } = await httpClient({ requiresAuth: false }).post('/login-staff', { username, password })
    if (token) {
      localStorage.setItem('staff', token.token)
      return true
    }
  },
  async profile() {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'staff' }).get(prefix+'/profile')

    return data
  },
  async get_users() {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'staff' }).get(prefix+'/get_users')

    return data
  },
  async get_promos() {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'staff' }).get(prefix+'/get_promos')

    return data
  },
  async update_promos(promotion_id, user_id) {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'staff' }).put(prefix+'/update_promos/'+user_id, { promotion_id })

    return data
  },
  async update_history(id, type, status) {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'staff' }).post(prefix+'/update-history', { id, type, status })

    return data
  },
  async check_game_play(id) {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'staff' }).get(prefix+'/user-play/'+id)

    return data
  },
  async update_user(id, body) {
    const { data } = await httpClient({ requiresAuth: true, auth_type: 'staff' }).put(prefix+'/update-user/'+id, body)

    return data
  }
}